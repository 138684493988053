// JavaScript Document

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { faCaretDown as fasFaCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes);

/**
 * Add solid icons
 */
library.add(fasFaCaretDown);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
